export const API = {
	LOGIN: {
		url: 'auth/login',
		type: 'post'
	},
	DOMICILE: {
		url: 'public/domicile',
		type: 'get'
	},

	LEADS: {
		url: 'outsource',
		type: 'get'
	},
	LEADS_CREATE: {
		url: 'outsource',
		type: 'post'
	},
	LEADS_UPDATE: {
		url: 'outsource',
		type: 'put'
	},
	LEADS_DELETE: {
		url: 'outsource',
		type: 'delete'
	},
	LEADS_GET: {
		url: 'outsource/get',
		type: 'get'
	},
	IMAGE_CREATE: {
		url: 'image',
		type: 'post'
	},
	IMAGE_DELETE: {
		url: 'image',
		type: 'delete'
	},

	USER_GET: {
		url: 'user',
		type: 'get'
	},
	USER_CREATE: {
		url: 'user',
		type: 'post'
	},



}